import React from "react"

import {graphql} from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/layout/seo"
import Section from "../../../components/section"
import Container from "../../../components/container"
import Box from "../../../components/box"
import HeaderSection from "../../../components/header"
import Animation from "../../../components/animation"
import Balloon from "../../../components/balloon";
import Contact from "../../../components/contact";

const OntaPage = ({_path, data}) => {
  return (
    <Layout path={"/leistungen/onta"}>
      <SEO title="Projekt ONTA"/>
      <HeaderSection
        title={"Projekt ONTA"}
        image={data.headerImage.childImageSharp}
        type={"image-fullscreen"}
      />
      <IntroSection/>
      <InfoSection/>
      <WorkSection/>
      <QuoteSection people={[data.balloonPerson1, data.balloonPerson2]} />
      <ResultSection/>
      <CooperationInterestsSection/>
      <ContactSection person={data.contactPerson}/>
    </Layout>
  )
}

const IntroSection = () => (
  <Section id={"IntroSection"}>
    <Container justify={"center"} direction={"column"} appear={"fromBottom"}>
      <Box alignText={"center"} padding={"horizontal"}>
        <p className={"text-size-bigger"}>
          <span className={"text-color-red text-weight-bold"}>
            ONT-Aktivierung
            <br/></span>
          <span>(Telekommunikationsbranche)<br/></span>
        </p>
        <p className={"text-size-bigger text-color-red text-weight-bold"}>
          &ndash;<br/>
        </p>
        <p className={"text-size-big"}>
          <span>Viel Vertrauen und grüne Wiese.<br/></span>
          <span>Wie aus Zeitdruck und fehlenden Anforderungen </span>
          <span className={"text-weight-bold"}> das </span>
          <span>Vorzeigeprojekt wurde.</span>
        </p>
      </Box>
    </Container>
  </Section>
)

const InfoSection = () => {
  
  const [paused, setPaused] = React.useState(true)
  
  return (
  <Section id={"InfoSection"}>
    <Container alignItems={"center"} appear={"fromLeft"}
    onAppear={() => setPaused(false)}>
      <Box>
            <Animation
              src={"Projekt-ONTA-1.json"}
              paused={paused}
            />
      </Box>
      <Box
        padding={"horizontal"}
      >
        <h2>Worum geht’s eigentlich?</h2>
        <p>
        Einen Internetzugang über DSL einzurichten, ist recht einfach:
         Das Kupferkabel wird angeschlossen und ist automatisch mit dem Netz verbunden.
          Um in einem passivem Glasfasernetz einen Internetzugang bereitstellen zu können,
           muss das Telekommunikationsunternehmen hingegen einen Optischen Netzwerkabschluss, kurz ONT, aktivieren.
        </p>
        <p>
        Dafür wurde eine Softwarelösung gesucht,
         welche anhand der Vertragsinformationen und der ONT Seriennummer diese automatisch dem Anschluss zuordnet
          und ihn in diesem Zug über eine Schnittstelle aktiviert.
        </p>
      </Box>
    </Container>
  </Section>
)
}

const WorkSection = () => {
  
const [paused, setPaused] = React.useState(true)
  
return (

  <Section id={"WorkSection"}>
    <Container alignItems={"center"} appear={"fromRight"}
      onAppear={() => setPaused(false)}>
      <Box
        padding={"horizontal"}
      >
        <h2>Wie sind wir die Sache angegangen?</h2>
        <p>
        Zu Beginn des Projekts waren neben dem Ziel keine final definierten Anforderungen ausformuliert,
         was für uns besonders spannend war! Der Problemlöse-Antrieb war geweckt.
          Wir begannen, uns zunächst in die fachlichen Anforderungen einzuarbeiten,
           um zu verstehen, wie die Software arbeiten muss.
        </p>
        <p>
        Wir haben in kürzester Zeit einen ersten funktionierenden Prototypen entwickelt
         und diesen in Feedbackschleifen mit dem Unternehmen zusammen bis zur Produktionsreife weiterentwickelt.
          Da wir also schnell eine konkrete und „anfassbare“ Software hatten,
           sparten wir uns einige theoretische und abstrakte Besprechungen und gingen sofort in eine produktive Softwareentwicklung über.
            Durch die schnelle Einarbeitung und Umsetzung konnten wir weitere Themen übernehmen und den Fortschritt des Gesamtprojektes stärkend vorantreiben.
        </p>
      </Box>
      <Box>
      <Animation
              src={"Projekt-ONTA-2.json"}
              paused={paused}
            />
      </Box>
    </Container>
  </Section>
)
}

const QuoteSection = (props) => (
  <Section id={"QuoteSection"}>
    <Container appear={"fromRight"} justify={"left"}>
      <Balloon justify={"left"} author={props.people[0]}>
        <p>
          Es ist immer einfacher, zu etwas Feedback zu geben, was schon vorhanden ist,
           als schon im Voraus konkrete Anforderungen zu stellen, obwohl man noch nicht weiß, wie es aussehen soll.
            Aus dem Grund ist es umso wichtiger, bei einer komplett neuen Software schnell einen Prototypen zu entwickeln und etwas Handfestes zu schaffen.
        </p>
      </Balloon>
    </Container>
  </Section>
)

const ResultSection = () => {
  
  const [paused, setPaused] = React.useState(true)
    
  return (
  <Section id={"ResultSection"}>
    <Container alignItems={"center"} appear={"fromLeft"}
     onAppear={() => setPaused(false)}>
    <Box>
            <Animation
              src={"Projekt-ONTA-1.json"}
              paused={paused}
            />
      </Box>
      <Box
        padding={"horizontal"}
      >
        <h2>Die grüne Wiese</h2>
        <p>
        Die sofortige Einbindung in die Organisation des Unternehmens und die sehr direkte,
         offene Kommunikation auf Augenhöhe ermöglichten uns ein sehr produktives Arbeiten.
          Wir stiegen mit wenigen Anforderungen, aber einem riesigen Vertrauensvorschuss in das Projekt ein
           und nutzten diesen enormen Freiraum. Mit Erfolg! Wir hatten so die Möglichkeit, uns in allen Bereichen Wissen anzueignen
            und konnten ohne Einschränkungen den bestmöglichen Ansatz verfolgen.
        </p>
        <p>
        Trotz des sehr straffen Zeitplans von ca. 4 Monaten konnte das Aktivierungstool zeitgerecht eingesetzt werden
         und funktionierte schon gleich nahezu fehlerfrei. Die Erfahrung zeigte:
          die Software ist leicht erweiterbar, wartbar und läuft reibungslos. Wir sind stolz und das Unternehmen ist zufrieden! 
        </p>
      </Box>
    </Container>
  </Section>
)
}

const CooperationInterestsSection = () => (
  <Section id={"CooperationInterestsSection"}>
    <Container justify={"center"} appear={"fromBottom"}>
      <Box alignText={"center"} padding={"horizontal"}>
        <p className={"text-size-bigger"}>
          <span>Sie sind an einer <wbr/></span>
          <span className={"text-color-red text-weight-bold"}>
            Zusammenarbeit interessiert
          </span>
          <span> und würden sich gerne beraten lassen?</span>
        </p>
        <p className={"text-size-bigger text-color-red text-weight-bold"}>
          &ndash;
        </p>
        <p className={"text-size-big"}>
          <span>Ihr Kontakt für alle Fragen rund um Projekte, Ideen und weitere Informationen:</span>
        </p>
      </Box>
    </Container>
  </Section>
)

const ContactSection = ({person}) => (
  <Section id={"ContactSection"} appear={"fromRight"}>
    <Container>
      <Box size={"2-to-3"}>
        <Contact
          imageFluid={person.frontmatter.square.childImageSharp.fluid}
          title={person.frontmatter.firstName + " " + person.frontmatter.lastName}
          subtitle={person.frontmatter.jobTitle}
          street={"Westbahnhof 11"}
          city={"38118 Braunschweig"}
          phone={person.frontmatter.phone}
          mail={person.frontmatter.mail}
          buttonType={"mail"}
          buttonUrl={person.frontmatter.mail}
          buttonText={"Kontakt aufnehmen"}
        />
      </Box>
    </Container>
  </Section>
)

export default OntaPage

export const query = graphql`
  query ONTAQuery {
    site {
      siteMetadata {
        title
      }
    }
    
    headerImage: file(relativePath: {eq: "services/onta/header.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    peopleAllMdx: allMdx(
      filter: { fileAbsolutePath: { regex: "/people/" } }
      sort: { order: ASC, fields: [frontmatter___position] }
    ) {
      edges {
        node {
          id
          body
          frontmatter {
            firstName
            lastName
            jobTitle
            avatar
            animation
            portrait {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            landscape {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    
    
    balloonPerson1: mdx(fileAbsolutePath: {regex: "/people/stephan-krauss/"}) {
      frontmatter {
        avatar
        firstName
        lastName
      }
    }
    
    contactPerson : mdx(fileAbsolutePath: {regex: "/people/matthias-geske/"}) {
      frontmatter {
        firstName
        lastName
        jobTitle
        mail
        phone
        square {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
